import React from "react";
import Navbar from "../Navbar/navbar";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import { SwiperSlide } from "swiper/react";
import "./blog.css";
import Footer from "../Footer/footer";
import Heading from "../HeadingContainer/heading";
import Contact from "../contactComponent/Contact";
import { titleChange } from "../titleChange";
import { Helmet } from "react-helmet";

const Blogs = () => {
  titleChange("Demolition and Excavation Blogs | ASA Group");

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Read the latest demolition and excavation blogs from ASA Demolisher. Stay up-to-date with the latest industry news and insights from our experts. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Projects Bangalore, Tree Removal Services in Bangalore, Construction Material Supply Services in Bangalore, Construction Waste Cleanup Services in Bangalore, overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Demolition Blog"
        child1="Blogs"
        style1={true}
        child1To="/blogs"
      />

      {/* blogs */}
      <section className="blogs" id="blogs">
        <h1 className="heading"> our blogs </h1>

        <div className="blogs-main-container">
          <div className="blogs-section">
            <SwiperSlide>
              <div className="swiper-slide slide" style={{ padding: 20 }}>
                <div className="image">
                  <img src={require("../../images/Blogs/blog-1.webp")} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h3>
                      How to Estimate the Cost of a Commercial Demolition
                      Project?
                    </h3>
                    <p>
                      Commercial demolition projects can be costly and complex,
                      with numerous variables that can impact the final cost.
                    </p>
                  </div>
                  <Link
                    to="/blogs/cost-of-demolition-in-bangalore"
                    className="btn"
                  >
                    read more
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide" style={{ padding: 20 }}>
                <div className="image">
                  <img src={require("../../images/Blogs/blog-2.webp")} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h3>What Makes A Good Demolition Company? </h3>
                    <p>
                      Demolition is a complex process that requires specialized
                      skills, equipment, and expertise.{" "}
                    </p>
                  </div>
                  <Link to="/blogs/good-demolition-company" className="btn">
                    read more
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide" style={{ padding: 20 }}>
                <div className="image">
                  <img src={require("../../images/Blogs/blog-3.webp")} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h3>
                      House Demolition Cost in Bangalore – Understanding the
                      Factors that Affect the Price
                    </h3>
                    <p>
                      Are you considering demolishing an old or dilapidated
                      property in Bangalore? Before you start the process, it's
                      essential to understand the cost involved.
                    </p>
                  </div>
                  <Link
                    to="/blogs/house-demolition-cost-bangalore"
                    className="btn"
                  >
                    read more
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide" style={{ padding: 20 }}>
                <div className="image">
                  <img src={require("../../images/Blogs/blog-4.webp")} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h3>
                      How to Choose the Experts for Demolition Services in
                      Bangalore
                    </h3>
                    <p>
                      Choosing the right experts for demolition services in
                      Bangalore is an important step in ensuring the success of
                      your project.
                    </p>
                  </div>
                  <Link
                    to="/blogs/experts-demolition-services-bangalore"
                    className="btn"
                  >
                    read more
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide" style={{ padding: 20 }}>
                <div className="image">
                  <img src={require("../../images/Blogs/blog-5.webp")} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h3>
                      Top 10 Reasons to Demolish Your Home/Building and Start
                      Again
                    </h3>
                    <p>
                      Outdated design: If your home or building has an outdated
                      design or layout, demolition and starting again may be the
                      best option to modernize the structure and increase its
                      functionality.
                    </p>
                  </div>
                  <Link
                    to="/blogs/top-10-reasons-demolish-home-bangalore"
                    className="btn"
                  >
                    read more
                  </Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide" style={{ padding: 20 }}>
                <div className="image">
                  <img src={require("../../images/Blogs/blog-3.webp")} alt="" />
                </div>
                <div className="content">
                  <div>
                    <h3>Thinking About Demolition Contractor in Bangalore?</h3>
                    <p>
                      Check licensing and insurance: Make sure that the
                      demolition contractor is licensed and insured.
                    </p>
                  </div>
                  <Link
                    to="/blogs/5-tips-before-demolition-bangalore"
                    className="btn"
                  >
                    read more
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </div>
      </section>
      <Contact />

      <Footer />
    </React.Fragment>
  );
};

export default Blogs;
