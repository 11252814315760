import React from "react";
import Footer from "../Footer/footer";
import Navbar from "../Navbar/navbar";
import Heading from "../HeadingContainer/heading";
import Contact from "../contactComponent/Contact";
import "./contactUs.css";
import { titleChange } from "../titleChange";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  titleChange("Contact ASA Demolisher | ASA Group");

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Contact ASA Demolisher for all your demolition and excavation needs in Bangalore. Get in touch with us for a quick response and professional service. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Projects Bangalore, Tree Removal Services in Bangalore, Construction Material Supply Services in Bangalore, Construction Waste Cleanup Services in Bangalore, overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading name="Contact Us" child1="Contact" child1To="/contact-us" />
      {/* Contact */}
      <div className="contact-heading-content">
        <h2>
          We are here to help and answer any questions you may have. If you have
          any inquiries or concerns, please do not hesitate to reach out to us.
        </h2>
        <p>You can get in touch with us through the following methods:</p>
        <ul>
          <li>Phone: 9538989075</li>
          <li>Email: asagroup581@gmail.com</li>
          <li>Online Form: Fill out the form below to send us a message</li>
          <li>Social Media: Connect with us on Facebook, Instagram</li>
        </ul>
        <p>
          We are committed to providing excellent customer service and will do
          our best to respond to your inquiry shortly.
        </p>
        <p>
          Thank you for visiting our website. We look forward to hearing from
          you!
        </p>
      </div>

      <Contact />

      <Footer />
    </React.Fragment>
  );
};

export default ContactUs;
