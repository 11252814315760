import React, { useState } from "react";
import Contact from "../contactComponent/Contact";
import Footer from "../Footer/footer";
import Heading from "../HeadingContainer/heading";
import Navbar from "../Navbar/navbar";
import "./project.css";
import ProjectContent from "./projectContentList";
import { titleChange } from "../titleChange";
import { Helmet } from "react-helmet";

const Project = () => {
  titleChange("Our Demolition Projects | ASA Group");

  const [dropDown, setDropDown] = useState(false);

  const handleSetDropDown = (item) => {
    if (item === dropDown) setDropDown("");
    else setDropDown(item);
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Discover the demolition projects completed by ASA Demolisher in Bangalore. Our team has a track record of delivering successful demolition projects. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Projects Bangalore, Tree Removal Services in Bangalore, Construction Material Supply Services in Bangalore, Construction Waste Cleanup Services in Bangalore, overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="ASA Demolition Projects in Bangalore KA"
        name2="RESIDENTIAL | COMMERCIAL | INDUSTRIAL"
        child1="Projects"
        child1To="/projects"
      />
      <div className="project-menu-container">
        <div className="project-heading-container">
          <h1>Our Projects</h1>
          <p>
            Here, we showcase some of our proudest achievements and demonstrate
            our expertise in the field. Our projects showcase the diverse range
            of services we offer, from residential to commercial, and
            demonstrate our commitment to delivering high-quality results every
            time.
          </p>
          <p>
            We have a track record of delivering projects on time and within
            budget, and we work closely with our clients to ensure their needs
            and expectations are met.
          </p>
          <p>
            Our portfolio includes commercial, industrial, real estate, civic
            body and residentials, each one showcasing our attention to detail
            and commitment to excellence.
          </p>
          <p>
            Whether you're looking for a small renovation or a major new
            construction project, we have the experience and expertise to bring
            your vision to life. Contact us today to learn more about how we can
            help with your next project.
          </p>
        </div>
        <div className="project-content-container">
          <div className="project-content-area">
            <ul>
              <ProjectContent
                name="Mahindra Demolition Project - Kanakapura Road Bangalore"
                content="Hey 1"
                id={12}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/nNudq3w05O8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 10.webp")}
                image11={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 11.webp")}
                image12={require("../../images/Projects-20230208T060446Z-001/Projects/Mahindra Demolition Project - Kanakapura Road Bangalore/Mahindra Demolition Project - Kanakapura Road Bangalore - 12.webp")}
              />

              <ProjectContent
                name="House Demolition - Jakkur, Yelahanka Road
                 Bangalore"
                content="Hey 1"
                id={12}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/wGQhcRHgS5E"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/10.webp")}
                image11={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/11.webp")}
                image12={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition - Jakkur, Yelahanka Road/12.webp")}
              />

              <ProjectContent
                name="Exterior Building Demolition - Attiguppe Bangalore"
                content="Hey 1"
                id={12}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/pmk1KkPbrMo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/3.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/4.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/6.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/7.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/9.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/1.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/5.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/10.webp")}
                image11={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/11.webp")}
                image12={require("../../images/Projects-20230208T060446Z-001/Projects/Exterior Building Demolition - Attiguppe Bangalore/12.webp")}
              />

              <ProjectContent
                name="Office Demolition - Kammanahalli Bangalore"
                content="Hey 1"
                id={12}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/i8Y4M0LgjsY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/10.webp")}
                image11={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/11.webp")}
                image12={require("../../images/Projects-20230208T060446Z-001/Projects/Office Demolition - Kammanahalli Bangalore/12.webp")}
              />

              <ProjectContent
                name="2 Floor Building Demolition - HBR Layout Bangalore"
                content="Hey 1"
                id={6}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/X6cdhtssY8s"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/2 Floor Building Demolition - HBR Layout Bangalore - ASA Group/2-Floor-Building-Demolition-HBR-Layout-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/2 Floor Building Demolition - HBR Layout Bangalore - ASA Group/2-Floor-Building-Demolition-HBR-Layout-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/2 Floor Building Demolition - HBR Layout Bangalore - ASA Group/2-Floor-Building-Demolition-HBR-Layout-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/2 Floor Building Demolition - HBR Layout Bangalore - ASA Group/2-Floor-Building-Demolition-HBR-Layout-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/2 Floor Building Demolition - HBR Layout Bangalore - ASA Group/2-Floor-Building-Demolition-HBR-Layout-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/2 Floor Building Demolition - HBR Layout Bangalore - ASA Group/2-Floor-Building-Demolition-HBR-Layout-Bangalore-6.webp")}
              />

              <ProjectContent
                name="Building Demolition - Basaveshwara Nagar Bangalore"
                content="Hey 2"
                id={9}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/ZYQmSg4_Da4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Basaveshwara Nagar Bangalore/Building-Demolition-Basaveshwara-Nagar-Bangalore-9.webp")}
              />

              <ProjectContent
                name="Building Demolition - Halasuru Bangalore"
                content="Hey 1"
                id={7}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/R8bJNOB5KPE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Halasuru Bangalore - ASA Group/Building-Demolition-Halasuru-Bangalore-7.webp")}
              />

              <ProjectContent
                name="Building Demolition - Indiranagar 100 Feet Road"
                content="Hey 3"
                id={6}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Gppzr8_Zm6A"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Indiranagar 100 Feet Road/Indiranagar-100-Feet-Road-Building-Demolition-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Indiranagar 100 Feet Road/Indiranagar-100-Feet-Road-Building-Demolition-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Indiranagar 100 Feet Road/Indiranagar-100-Feet-Road-Building-Demolition-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Indiranagar 100 Feet Road/Indiranagar-100-Feet-Road-Building-Demolition-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Indiranagar 100 Feet Road/Indiranagar-100-Feet-Road-Building-Demolition-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Indiranagar 100 Feet Road/Indiranagar-100-Feet-Road-Building-Demolition-6.webp")}
              />

              <ProjectContent
                name="Building Demolition - Kalyan Nagar Bangalore"
                content="Hey 2"
                id={3}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/BSWMXBc787U"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Kalyan Nagar Bangalore/Building-Demolition-Kalyan-Nagar-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Kalyan Nagar Bangalore/Building-Demolition-Kalyan-Nagar-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Building Demolition - Kalyan Nagar Bangalore/Building-Demolition-Kalyan-Nagar-Bangalore-3.webp")}
              />

              <ProjectContent
                name="Bungalow Demolition - Frazer Town Bangalore"
                content="Hey 3"
                id={7}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/FBhwZztCfx8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Bungalow Demolition - Frazer Town Bangalore/Bungalow-Demolition-Frazer-Town Bangalore-7.webp")}
              />

              <ProjectContent
                name="Factory Demolition - Doddaballapura Bangalore"
                content="Hey 1"
                id={12}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/r1L_DkyhtLc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-10.webp")}
                image11={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-11.webp")}
                image12={require("../../images/Projects-20230208T060446Z-001/Projects/Factory Demolition - Doddaballapura Bangalore - ASA Group/Factory-Demolition-Doddaballapura-Bangalore-Rural-ASA-Group-12.webp")}
              />

              <ProjectContent
                name="House Demolition - Narayanaswamappa Layout, Jakkur Bangalore"
                content="Hey 2"
                id={7}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/gPYPSeC_eW8"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 3 Days - Narayanaswamappa Layout, Jakkur Main Road Bangalore KA/House-Demolition -Jakkur-Main-Road-Bangalore-7.webp")}
              />

              <ProjectContent
                name="House Demolition - Yelahanka New Town Bangalore KA"
                content="Hey 3"
                id={9}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/2FrxkpkDBnc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-8.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-9.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/House Demolition in 5 Days - Yelahanka New Town Bangalore KA/House-Demolition-Yelahanka-New-Town-Bangalore-10.webp")}
              />

              <ProjectContent
                name="Old House Demolition - Gubbi Cross Kothanur Bangalore"
                content="Hey 1"
                id={3}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Gubbi Cross Kothanur Bangalore/Old-House-Demolition-Gubbi-Cross-Bangalore-1 (1).webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Gubbi Cross Kothanur Bangalore/Old-House-Demolition-Gubbi-Cross-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Gubbi Cross Kothanur Bangalore/Old-House-Demolition-Gubbi-Cross-Bangalore-3.webp")}
              />

              <ProjectContent
                name="Old House Demolition - K R Puram Bangalore"
                content="Hey 2"
                id={2}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - K R Puram Bangalore/KR-Puram-House-Demolition-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - K R Puram Bangalore/KR-Puram-House-Demolition-Bangalore-2.webp")}
              />

              <ProjectContent
                name="Old House Demolition - Lingarajapuram Bangalore"
                content="Hey 3"
                id={10}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/mFBmjbTXAtY"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Lingarajapuram Bangalore - ASA Group/old-house-demolition-lingarajapuram-10.webp")}
              />

              <ProjectContent
                name="Old House Demolition - Ramamurthy Nagar Bangalore"
                content="Hey 1"
                id={6}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Ramamurthy Nagar Bangalore/Old House Demolition - Ramamurthy Nagar Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Ramamurthy Nagar Bangalore/Old House Demolition - Ramamurthy Nagar Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Ramamurthy Nagar Bangalore/Old House Demolition - Ramamurthy Nagar Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Ramamurthy Nagar Bangalore/Old House Demolition - Ramamurthy Nagar Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Ramamurthy Nagar Bangalore/Old House Demolition - Ramamurthy Nagar Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Old House Demolition - Ramamurthy Nagar Bangalore/Old House Demolition - Ramamurthy Nagar Bangalore-6.webp")}
              />

              <ProjectContent
                name="Overhead Tank Demolition - RK Hegde Nagar"
                content="Hey 2"
                id={10}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/Ig1TtLgTYRs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Overhead Tank Demolition - RK Hegde Nagar/Overhead-Tank-Demolition-RK-Hegde-Nagar-10.webp")}
              />

              <ProjectContent
                name="Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore"
                content="Hey 3"
                id={8}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/U9bLK3PxsjE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Sudhir Weigh Bridge Demolition - Old Madras Road Bangalore - ASA Group/Sudhir-Weigh-Bridge-Demolition-Old-Madras-Road-Bangalore-8.webp")}
              />

              <ProjectContent
                name="Tree Removal - House Demolition - SultanPalya Bangalore"
                content="Hey 3"
                id={6}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/EZgrPljCVJE"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ House Demolition - SultanPalya Bangalore - ASA Group/Tree-Removal-and-House-Demolition-Sultan-Palya-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ House Demolition - SultanPalya Bangalore - ASA Group/Tree-Removal-and-House-Demolition-Sultan-Palya-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ House Demolition - SultanPalya Bangalore - ASA Group/Tree-Removal-and-House-Demolition-Sultan-Palya-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ House Demolition - SultanPalya Bangalore - ASA Group/Tree-Removal-and-House-Demolition-Sultan-Palya-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ House Demolition - SultanPalya Bangalore - ASA Group/Tree-Removal-and-House-Demolition-Sultan-Palya-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ House Demolition - SultanPalya Bangalore - ASA Group/Tree-Removal-and-House-Demolition-Sultan-Palya-Bangalore-6.webp")}
              />

              <ProjectContent
                name="Tree Removal - Old House Demolition - Frazer Town Bangalore"
                content="Hey 3"
                id={10}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/XkkxpXZIBYo"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Tree Removal _ Old House Demolition - Frazer Town Bangalore/Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-10.webp")}
              />

              <ProjectContent
                name="Yelahanka New Town - Building Demolition Project"
                content="Hey 3"
                id={12}
                dropDown={dropDown}
                setDropDown={handleSetDropDown}
                youtube={
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/0PvIPU_FgJc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                }
                image1={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-1.webp")}
                image2={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-2.webp")}
                image3={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-3.webp")}
                image4={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-4.webp")}
                image5={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-5.webp")}
                image6={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-6.webp")}
                image7={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-7.webp")}
                image8={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-8.webp")}
                image9={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-9.webp")}
                image10={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-10.webp")}
                image11={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-11.webp")}
                image12={require("../../images/Projects-20230208T060446Z-001/Projects/Yelahanka New Town - Building Demolition Project - ASA Groups/Yelahanka-New-Town-Building-Demolition-12.webp")}
              />
            </ul>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Project;
