import React from "react";
import Navbar from "../Navbar/navbar";
import "./about.css";
import Footer from "../Footer/footer";
import Heading from "../HeadingContainer/heading";
import Contact from "../contactComponent/Contact";
import { titleChange } from "../titleChange";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  titleChange("About Us - ASA Group");
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Learn about ASA Building Demolition Contractor, our team, and the demolition and excavation services we offer. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        img={require("../../images/digger.jpg")}
        name="About Us"
        child1="About Us"
        child1To="/about-us"
      />
      <div className="about-container">
        <div className="team-img">
          <img
            className="img-team"
            src={require("../../images/Team.jpeg")}
            alt=""
          />
          <img
            className="img-team-1"
            src={require("../../images/Team-1.jpeg")}
            alt=""
          />
        </div>
        <div className="main-container">
          <div className="content">
            <h1 className="content-heading">
              Since 1990...Being The Best in Demolition Business
            </h1>
            <p className="content-info">
              ASA Group was established in 1990 by Mohammed Zia Ulla. We
              specialize in bringing down buildings - But it is more than just
              that.
            </p>
            <p className="content-info">
              The company was established by our founder and Managing Director,
              Mr. Zia, with the goal of offering clients a professional and
              cost-effective demolition service, leveraging his expertise in the
              demolition and construction industry. This has been accomplished
              by providing guidance on the appropriate methodology, type, and
              sequence of demolition and engineering solutions, with a strong
              emphasis on health, safety, and well-being.
            </p>
            <p className="content-info">
              Throughout the years, we have recruited experienced and highly
              skilled staff who are passionate about their work. This has
              allowed us to expand the range of services we offer to clients. We
              are selective in choosing the right individuals, resulting in a
              tight-knit team that operates like a family. This is exactly the
              experience we want our clients to have when they work with us. It
              is crucial that we maintain honesty and realism with our clients
              so that we can ensure the job is done correctly and exceed their
              expectations.
            </p>
          </div>
        </div>
        <div className="about-points">
          <h1 className="heading" style={{ fontWeight: 500 }}>
            Why Choose Us
          </h1>
          <ul className="about-point">
            <li>
              <p>Trusted service</p>
            </li>
            <li>
              <p>Experienced technicians</p>
            </li>
            <li>
              <p>Less time required</p>
            </li>
            <li>
              <p>Advanced technology</p>
            </li>
            <li>
              <p>Affordable estimation</p>
            </li>
            <li>
              <p>Quick response</p>
            </li>
          </ul>
        </div>
      </div>
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default AboutUs;
