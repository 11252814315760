import React from "react";
import Contact from "../../contactComponent/Contact";
import Footer from "../../Footer/footer";
import Navbar from "../../Navbar/navbar";
import Heading from "../../HeadingContainer/heading";
import Service from "../service";
import { titleChange } from "../../titleChange";
import { Helmet } from "react-helmet";

const DebrisCleanup = () => {
  titleChange("Construction Waste Cleanup Services in Bangalore | ASA Group");
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Demolisher offers reliable construction waste cleanup services in Bangalore. Our team will remove and dispose of all construction waste efficiently. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Construction Waste Cleanup Services in Bangalore, overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Debris/Construction Waste Cleanup in Bangalore"
        name2="Dispose in an Environmentally Responsible Manner"
        child1="Serivces"
        child1To="/services"
        child2="Construction Waste Cleanup Bangalore"
        child2To="/services/construction-waste-cleanup-bangalore"
      />
      <Service
        para={
          <div>
            <p>
              Construction waste cleanup is a service that involves the removal
              and disposal of waste and debris generated during construction,
              renovation, or demolition projects. The waste generated during
              construction projects can include a wide range of materials,
              including wood, concrete, metal, and plastic.
            </p>
            <p>
              Construction waste cleanup services typically involve the
              following steps:
            </p>
            <ul>
              <li>
                Collection: The waste and debris generated during the
                construction project is collected and transported to a central
                location for disposal. This may involve using heavy machinery,
                such as dump trucks, or manual labor to move the waste.
              </li>
              <li>
                Sorting: The waste is sorted into different categories, such as
                recyclables, hazardous waste, and non-recyclables. This helps to
                ensure that the waste is disposed of in an environmentally
                responsible manner.
              </li>
              <li>
                Disposal: The sorted waste is then disposed of according to
                local regulations and best practices. This may involve
                recycling, incineration, or landfill disposal.
              </li>
              <li>
                Cleanup: The site is cleaned up and any residual waste is
                removed. This helps to ensure the safety of the area and prevent
                any further damage or contamination.
              </li>
            </ul>
            <p>
              Construction waste cleanup services are an important part of the
              construction process, as they help to keep construction sites
              clean and safe, and reduce the environmental impact of
              construction projects. It is important to choose a reputable and
              experienced construction waste cleanup company to ensure that the
              waste is handled and disposed of in an environmentally responsible
              manner.
            </p>
          </div>
        }
        img={require("./Tree-Removal-and-Old-House-Demolition-Frazer-Town-Bangalore-6.jpeg")}
      />

      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default DebrisCleanup;
