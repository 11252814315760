import React, { Suspense } from "react";
import Navbar from "../Navbar/navbar";
import "./home.css";
import Footer from "../Footer/footer";
import Typewriter from "react-ts-typewriter";
import { titleChange } from "../titleChange";
import { Helmet } from "react-helmet";
import HAbout from "./HAbout";
import HServices from "./HServices";
import HProjects from "./HProjects";
import HReviews from "./HReviews";
import HBlogs from "./HBlogs";
const Contact = React.lazy(() => import("../contactComponent/Contact"));

const Home = () => {
  titleChange(
    "Demolition Contractor Near Me in Bangalore, Building Demolition Contractor Near Me in Bangalore, Best Demolition Company Near Me in Bangalore"
  );

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Building Demolition Contractor is a leading demolition and excavation company in Bangalore KA, providing quality services to commercial, residential and industrial clients. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore"
        />
      </Helmet>
      {/* Navbar */}
      <Navbar />

      <section className="home" id="home">
        <div className="swiper-slide slide s1">
          <div className="background-overlay">
            <div className="content">
              <h3>Welcome to ASA Group</h3>
              <h1>Best Demolition Contractor</h1>
              <h2>Bangalore</h2>
              <h4>
                <Typewriter
                  loop={true}
                  speed={40}
                  text="Trusted | 30 Years of Experience | On-Time Delivery"
                  delay={1000}
                  random={100}
                />
              </h4>
              <a
                href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                className="google-badge"
              >
                <img src={require("../../images/googleBadge-3.webp")} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* About */}
      <HAbout />

      {/* services */}
      <HServices />

      {/* Projects */}
      <HProjects />

      {/* Reviews */}
      <HReviews />

      {/* blogs */}
      <HBlogs />

      <Suspense fallback={<p>Loading...</p>}>
        <Contact />
      </Suspense>

      {/* Footer */}
      <Footer />
    </React.Fragment>
  );
};

export default Home;
