import React from "react";
import { Link } from "react-router-dom";
// import video from "../../video/videoplayback.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPhoneSquare } from "@fortawesome/free-solid-svg-icons";
import logo from "../../images/ASA-GROUP-PNG.webp";
import Contact from "../contactComponent/Contact";
import Typewriter from "react-ts-typewriter";
import HAbout from "../Home/HAbout";
import HReviews from "../Home/HReviews";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const CHome = () => {
  return (
    <React.Fragment>
      {/* Navbar */}

      <div className="header-camp">
        <Link className="logo">
          <img className="logo-image-camp" src={logo} alt="" />
        </Link>

        <a style={{ color: "black" }} href="tel:812-398-9074">
          <p id="nav-phone">
            <FontAwesomeIcon icon={faPhoneSquare} id="phone-icon" />
            812-398-9074
          </p>
        </a>
      </div>

      <section className="home" id="home">
        <div className="swiper-slide slide s1">
          <div className="background-overlay">
            <div className="content">
              <div className="home-transition">
                <h3>Welcome to ASA Group</h3>
                <h1>Best Demolition Contractor</h1>
                <h2>Bangalore</h2>
              </div>
              <p>
                <h4>
                  <Typewriter
                    loop={true}
                    speed={40}
                    text="Trusted | 30 Years of Experience | On-Time Delivery"
                    delay={1000}
                    random={100}
                  />
                </h4>
              </p>

              <a
                href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                className="google-badge"
              >
                <img src={require("../../images/googleBadge-3.webp")} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Reviews */}
      <HReviews />

      {/* services */}

      {/* Projects */}

      <section className="projects" id="projects">
        <h1 className="heading"> our projects </h1>
        <div className="box-container">
          <Link className="box">
            <div className="image">
              <img
                src={require("../../images/landing page project images/1 Week - Building Demolition - Halasuru Bangalore - ASA Group.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <div className="info">
                <h3>Demolished in Just 10 Days</h3>
                <p>HBR Layout Bangalore</p>
              </div>
              {/* <div className="plus-icon-container">
                                        <FontAwesomeIcon 
                                        className='plus-icon'
                                        icon={faPlus} />
                                    </div> */}
            </div>
          </Link>
          <Link className="box">
            <div className="image">
              <img
                src={require("../../images/landing page project images/1 Week -Indiranagar-100-Feet-Road-Building-Demolition.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <div className="info">
                <h3>Bulldozed in 1 Week</h3>
                <p>Indiranagar Bangalore</p>
              </div>
              {/* <div className="plus-icon-container">
                                        <FontAwesomeIcon 
                                        className='plus-icon'
                                        icon={faPlus} />
                                    </div> */}
            </div>
          </Link>
          <Link className="box">
            <div className="image">
              <img
                src={require("../../images/landing page project images/2 Weeks -Bungalow-Demolition-Frazer-Town Bangalore.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <div className="info">
                <h3>Razed in Just 2 Weeks</h3>
                <p>Frazer Town Bangalore</p>
              </div>
              {/* <div className="plus-icon-container">
                                        <FontAwesomeIcon 
                                        className='plus-icon'
                                        icon={faPlus} />
                                    </div> */}
            </div>
          </Link>
          <Link className="box">
            <div className="image">
              <img
                src={require("../../images/landing page project images/3 Day Challenge - Old House Demolition - Lingarajapuram Bangalore.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <div className="info">
                <h3>3 Day Challenge</h3>
                <p>Lingarajapuram Bangalore</p>
              </div>
              {/* <div className="plus-icon-container">
                                        <FontAwesomeIcon 
                                        className='plus-icon'
                                        icon={faPlus} />
                                    </div> */}
            </div>
          </Link>
          <Link className="box">
            <div className="image">
              <img
                src={require("../../images/landing page project images/3 Day Challenge House-Demolition -Jakkur-Main-Road-Bangalore.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <div className="info">
                <h3>Knocked Down 3 Day</h3>
                <p>Jakkur-Main-Road-Bangalore</p>
              </div>
              {/* <div className="plus-icon-container">
                                        <FontAwesomeIcon 
                                        className='plus-icon'
                                        icon={faPlus} />
                                    </div> */}
            </div>
          </Link>
          <Link className="box">
            <div className="image">
              <img
                src={require("../../images/landing page project images/5 Day Challenge - 2-Floor-Building-Demolition-HBR-Layout-Bangalore.jpg")}
                alt=""
              />
            </div>
            <div className="content">
              <div className="info">
                <h3>Completed in Just 5 Days</h3>
                <p>HBR-Layout-Bangalore</p>
              </div>
              {/* <div className="plus-icon-container">
                                        <FontAwesomeIcon 
                                        className='plus-icon'
                                        icon={faPlus} />
                                    </div> */}
            </div>
          </Link>
        </div>
      </section>

      {/* About */}
      <HAbout />

      <div className="fixed-icon">
        <a href={"https://wa.me/8123989074"}>
          <div id="whatsapp-button">
            <FontAwesomeIcon id="whatsapp-icon" icon={faWhatsapp} />
          </div>
        </a>

        <a href="tel:812-398-9074">
          <div id="Call-now-button">
            <FontAwesomeIcon icon={faPhone} id="phone-icon1" />
          </div>
        </a>
      </div>

      <Contact />

      {/* Footer */}
      <footer className="footer">
        <div className="footer-1">
          <div className="footer-1-Links">
            <Link>Terms & Conditions</Link>
            <span>|</span>
            <Link>Privacy Policy </Link>
          </div>
          <div className="footer-1-icons">
            <Link
              href="https://www.facebook.com/asademolishers "
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link
              href="https://www.instagram.com/asademolition/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link href="https://wa.me/8123989074" target="_blank">
              <FontAwesomeIcon icon={faWhatsapp} />
            </Link>
            <Link
              href="https://www.youtube.com/@asademolisher.com-bangalore"
              target="_blank"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
          </div>
        </div>
        <div className="footer-2">
          <p>Copyright © ASA Group. All Rights Reserved.</p>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default CHome;
