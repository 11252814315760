import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Contact from "../contactComponent/Contact";
import Footer from "../Footer/footer";
import Heading from "../HeadingContainer/heading";
import Navbar from "../Navbar/navbar";
import { titleChange } from "../titleChange";
import "./services.css";

const Services = () => {
  titleChange("Our Demolition Services - ASA Group");
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Building Demolition Contractor  provides a wide range of demolition and excavation services for commercial, residential, and industrial clients. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Our Demolition Services in Bangalore KA"
        style1={true}
        child1="Services"
        child1To="/services"
      />
      {/* services */}
      <section className="services" id="services">
        <h1 className="heading"> our services </h1>
        <div className="box-container">
          <Link
            className="box"
            to="/services/old-building-demolition-bangalore"
          >
            {/* <div className="box"> */}
            <img src={require("../../images/services/service-2.png")} alt="" />
            <h3>Old Building Demolition</h3>
            <p>Destroying the Structure with Controlled Methods</p>
            {/* </div> */}
          </Link>
          <Link className="box" to="/services/interior-demolition-bangalore">
            {/* <div className="box"> */}
            <img src={require("../../images/services/service-8.png")} alt="" />
            <h3>Interior Demolition</h3>
            <p>Deconstruct and Renovate to Create New Facilities</p>
            {/* </div> */}
          </Link>
          <Link
            className="box"
            to="/services/concrete-rcc-demolition-bangalore"
          >
            {/* <div className="box"> */}
            <img
              src={require("../../images/icons/concrete-demolition-bangalore.webp")}
              alt=""
            />
            <h3>Concrete (RCC) Demolition</h3>
            <p>Efficiently Breaking Down to Small Pieces </p>
            {/* </div> */}
          </Link>
          <Link className="box" to="/services/earth-excavation-bangalore">
            {/* <div className="box"> */}
            <img
              src={require("../../images/icons/excavation-digging-bangalore.webp")}
              alt=""
            />
            <h3>Excavation/Dig</h3>
            <p>A Quick Dig for a Strong Foundation.</p>
            {/* </div> */}
          </Link>
          <Link
            className="box"
            to="/services/overhead-tank-demolition-bangalore"
          >
            {/* <div className="box"> */}
            <img
              src={require("../../images/icons/overhead-tank-demolition-bangalore.webp")}
              alt=""
            />
            <h3>Overhead Tank Demolition</h3>
            <p>Implode the High Rise to Bite the Dust</p>
            {/* </div> */}
          </Link>
          <Link
            className="box"
            to="/services/construction-waste-cleanup-bangalore"
          >
            {/* <div className="box"> */}
            <img
              src={require("../../images/icons/debris-construction-waste-cleanup-bangalore.webp")}
              alt=""
            />
            <h3>Debris Cleanup</h3>
            <p>Dispose in an Environmentally Responsible Manner</p>
            {/* </div> */}
          </Link>
          <Link
            className="box"
            to="/services/boilers-chimneys-demolition-bangalore"
          >
            {/* <div className="box"> */}
            <img
              src={require("../../images/icons/boiler-chimney-demolition.webp")}
              alt=""
            />
            <h3>Boilers and chimneys demolition </h3>
            <p>
              Simplifying Complex and Dangerous Demolition with the Best
              Standards
            </p>
            {/* </div> */}
          </Link>
          <Link
            className="box"
            to="/services/construction-material-supply-bangalore"
          >
            {/* <div className="box"> */}
            <img src={require("../../images/services/service-4.webp")} alt="" />
            <h3>Material Supply</h3>
            <p>High Quality Materials Delivered On-time</p>
            {/* </div> */}
          </Link>
          <Link className="box" to="/services/tree-removal-service-bangalore">
            {/* <div className="box"> */}
            <img
              src={require("../../images/icons/tree-removal-bangalore.webp")}
              alt=""
            />
            <h3>Tree Cutting Service</h3>
            <p>Clear Your Land for Construction</p>
            {/* </div> */}
          </Link>
        </div>
      </section>
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default Services;
