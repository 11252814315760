import { Link } from "react-router-dom";

const HProjects = () => {
  return (
    <section className="projects" id="projects">
      <h1 className="heading"> our projects </h1>
      <div className="box-container">
        <Link to="/projects" className="box">
          <div className="image">
            <img
              loading="lazy"
              src={require("../../images/OurProjects/bungalow-demolition-bangalore.webp")}
              alt=""
            />
          </div>
          <div className="content">
            <div className="info">
              <h3>Building Demolition</h3>
              <p>Safe and Efficient</p>
            </div>
          </div>
        </Link>
        <Link to="/projects" className="box">
          <div className="image">
            <img
              loading="lazy"
              src={require("../../images/OurProjects/overhead-tank-demolition-bangalore.webp")}
              alt=""
            />
          </div>
          <div className="content">
            <div className="info">
              <h3>Overhead Tank Demolition</h3>
              <p>Amazing Collapse</p>
            </div>
          </div>
        </Link>
        <Link to="/projects" className="box">
          <div className="image">
            <img
              loading="lazy"
              src={require("../../images/OurProjects/factory-demolition-bangalore.webp")}
              alt=""
            />
          </div>
          <div className="content">
            <div className="info">
              <h3>Factory Demolition</h3>
              <p>Steel Beams</p>
            </div>
          </div>
        </Link>
        <Link to="/projects" className="box">
          <div className="image">
            <img
              loading="lazy"
              src={require("../../images/OurProjects/weigh-bridge-demolition-bangalore.webp")}
              alt=""
            />
          </div>
          <div className="content">
            <div className="info">
              <h3>Weigh Bridge Demolition</h3>
              <p>Hard and Robust</p>
            </div>
          </div>
        </Link>
        <Link to="/projects" className="box">
          <div className="image">
            <img
              loading="lazy"
              src={require("../../images/OurProjects/tree-removal-bangalore.webp")}
              alt=""
            />
          </div>
          <div className="content">
            <div className="info">
              <h3>Tree Removal Bangalore</h3>
              <p>Chop to Small Pieces</p>
            </div>
          </div>
        </Link>
        <Link to="/projects" className="box">
          <div className="image">
            <img
              loading="lazy"
              src={require("../../images/OurProjects/rcc-concrete-slab-cutting-bangalore.webp")}
              alt=""
            />
          </div>
          <div className="content">
            <div className="info">
              <h3>RCC Concrete Slab Cutting</h3>
              <p>Damage Proof</p>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default HProjects;
