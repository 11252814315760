import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <>
      <React.Fragment>
        <footer className="footer">
          <div className="footer-1">
            <div className="footer-1-Links">
              <Link to="#">Terms & Conditions</Link>
              <span>|</span>
              <Link to="#">Privacy Policy </Link>
              <span>|</span>
              <Link to="/contact-us">Contact Us</Link>
              <span>|</span>
              <Link to="/sitemap">Sitemap</Link>
            </div>
            <div className="footer-1-icons">
              <Link
                href="https://www.facebook.com/asademolishers "
                target={"_blank"}
              >
                <i className="fa-brands fa-facebook-square"></i>
              </Link>
              <Link
                href="https://www.instagram.com/asademolition/"
                target={"_blank"}
              >
                <i className="fa-brands fa-instagram"></i>
              </Link>
              <Link href="https://wa.me/9066402200" target={"_blank"}>
                <i className="fa-brands fa-whatsapp"></i>
              </Link>
              <Link
                href="https://www.youtube.com/@asademolisher.com-bangalore"
                target={"_blank"}
              >
                <i className="fa-brands fa-youtube"></i>
              </Link>
            </div>
          </div>
          <div className="footer-2">
            <p>Copyright © ASA Group. All Rights Reserved.</p>
          </div>
        </footer>
      </React.Fragment>

      <div className="fixed-icon">
        <div className="whatsapp-button">
          <Link href="https://wa.me/9538989075" target={"_blank"}>
            <FontAwesomeIcon icon={faWhatsapp} />
          </Link>
        </div>

        <div className="Call-now-button">
          <a href="tel:953-898-9075">
            <FontAwesomeIcon icon={faPhone} className="phone-icon1" />
          </a>
        </div>
      </div>
    </>
  );
};

export default Footer;
