import { Link } from "react-router-dom";

const HAbout = () => {
  return (
    <section className="about" id="about">
      <h1 className="heading"> about us </h1>
      <div className="row1">
        <div className="video">
          {/* <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wG-Jc1TAc9k?si=4Sd5RkqmEx9aJqJg"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
          <iframe
            title="Youtube"
            srcdoc="
          <style>
              body, .full {
                  width: 100%;
                  height: 100%;
                  margin: 0;
                  position: absolute;
                  display: flex;
                  justify-content: center;
                  object-fit: cover;
              }
          </style>
          <a
              href='https://www.youtube.com/embed/wG-Jc1TAc9k?si=4Sd5RkqmEx9aJqJg'
              class='full'
          >
              <img
                  src='https://asademolisher.com/static/media/blog-3.b3aa7ca9b147ca632dce.webp'
                  class='full'
              />
              <svg
                  version='1.1'
                  viewBox='0 0 68 48'
                  width='68px'
                  style='position: relative;'
              >
                  <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                  <path d='M 45,24 27,14 27,34' fill='#fff'></path>
              </svg>
          </a>
      "
            frameborder="0"
          ></iframe>
        </div>
        <div className="content">
          <h3>Since 1990...Being The Best in Demolition Business</h3>
          <p>
            As a leading contractor of demolition in the Bangalore area, we take
            pride in offering the best demolition services available. We have
            30+ years of experience, utilize the latest machineries, and our
            team is highly trained to ensure you get the best demolition service
            possible.{" "}
          </p>
          <p>
            We offer <span style={{ fontWeight: 600 }}>FREE-VISITS</span> and
            pre-plan to ensure that all our demolition work is carried out in an
            organized and efficient manner. With extensive experience of working
            in Bangalore KA, you can rest assured that you will receive the best
            advice and VALUE FOR YOUR MONEY.
          </p>
          <Link to="/about-us" className="btn">
            read more
          </Link>
        </div>
      </div>
      <div className="box-container">
        <div className="box">
          <h3>30+</h3>
          <p>years of experience</p>
        </div>
        <div className="box">
          <h3>1500+</h3>
          <p>project completed</p>
        </div>
        <div className="box">
          <h3>790+</h3>
          <p>satiesfied clients</p>
        </div>
        <div className="box">
          <h3>80+</h3>
          <p>active workers</p>
        </div>
      </div>
    </section>
  );
};

export default HAbout;
