import { Link } from "react-router-dom";

const HServices = () => {
  return (
    <section className="services" id="services">
      <h1 className="heading"> our services </h1>
      <div className="box-container">
        <Link className="box" to="/services/old-building-demolition-bangalore">
          <img
            loading="lazy"
            src={require("../../images/services/service-2.webp")}
            alt=""
          />
          <h3>Old Building Demolition</h3>
          <p>Destroying the Structure with Controlled Methods</p>
          {/* </div> */}
        </Link>
        <Link className="box" to="/services/interior-demolition-bangalore">
          <img
            loading="lazy"
            src={require("../../images/services/service-8.png")}
            alt=""
          />
          <h3>Interior Demolition</h3>
          <p>Deconstruct and Renovate to Create New Facilities</p>
        </Link>
        <Link className="box" to="/services/concrete-rcc-demolition-bangalore">
          <img
            loading="lazy"
            src={require("../../images/icons/concrete-demolition-bangalore.webp")}
            alt=""
          />
          <h3>Concrete (RCC) Demolition</h3>
          <p>Efficiently Breaking Down to Small Pieces </p>
          {/* </div> */}
        </Link>
        <Link className="box" to="/services/earth-excavation-bangalore">
          <img
            loading="lazy"
            src={require("../../images/icons/excavation-digging-bangalore.webp")}
            alt=""
          />
          <h3>Excavation/Dig</h3>
          <p>A Quick Dig for a Strong Foundation.</p>
        </Link>
        <Link className="box" to="/services/overhead-tank-demolition-bangalore">
          <img
            loading="lazy"
            src={require("../../images/icons/overhead-tank-demolition-bangalore.webp")}
            alt=""
          />
          <h3>Overhead Tank Demolition</h3>
          <p>Implode the High Rise to Bite the Dust</p>
        </Link>
        <Link
          className="box"
          to="/services/construction-waste-cleanup-bangalore"
        >
          <img
            loading="lazy"
            src={require("../../images/icons/debris-construction-waste-cleanup-bangalore.webp")}
            alt=""
          />
          <h3>Debris Cleanup</h3>
          <p>Dispose in an Environmentally Responsible Manner</p>
          {/* </div> */}
        </Link>
        <Link
          className="box"
          to="/services/boilers-chimneys-demolition-bangalore"
        >
          <img
            loading="lazy"
            src={require("../../images/icons/boiler-chimney-demolition.webp")}
            alt=""
          />
          <h3>Boilers and chimneys demolition </h3>
          <p>
            Simplifying Complex and Dangerous Demolition with the Best Standards
          </p>
        </Link>
        <Link
          className="box"
          to="/services/construction-material-supply-bangalore"
        >
          <img
            loading="lazy"
            src={require("../../images/services/service-4.webp")}
            alt=""
          />
          <h3>Material Supply</h3>
          <p>High Quality Materials Delivered On-time</p>
        </Link>
        <Link className="box" to="/services/tree-removal-service-bangalore">
          <img
            loading="lazy"
            src={require("../../images/icons/tree-removal-bangalore.webp")}
            alt=""
          />
          <h3>Tree Cutting Service</h3>
          <p>Clear Your Land for Construction</p>
        </Link>
      </div>
    </section>
  );
};

export default HServices;
