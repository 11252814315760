import React from "react";
import "./services.css";

const Service = (props) => {
  return (
    <React.Fragment>
      <div className="service-sections">
        <div className="service-section-container">
          <div className="service-section-img">
            <img src={props.img} alt="" />
          </div>
          <div className="service-section-content">{props.para}</div>
        </div>
        <div className="extra-content">{props.extraContent}</div>
      </div>
    </React.Fragment>
  );
};

export default Service;
