import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/home";
import ContactUs from "./components/Contact Us/contactUs";
import AboutUs from "./components/About Us/AboutUs";
import Blogs from "./components/Blog/blogs";
import BuildingDemolition from "./components/services/BuildingDemolition/BuildingDemolition";
import InteriorDemolition from "./components/services/InteriorDemolition/interiorDemolition";
import ConcreteDemolition from "./components/services/ConcreteDemoltion/concreteDemoltion";
import Excation from "./components/services/Excation/excavation";
import TankDemolition from "./components/services/OverheadTankDemolition/tankDemolition";
import DebrisCleanup from "./components/services/DebrisCleanup/debrisCleanup";
import ChimneyDemolition from "./components/services/BoilersAndChimneysDemolition/chimneysDemoliton";
import MaterialSUpply from "./components/services/MaterialSupply/materialSupply";
import TreeRemoval from "./components/services/TreeRemoval/treeRemoval";
import Project from "./components/Projects/project";
import BlogOne from "./components/Blog/blog-1";
import BlogThree from "./components/Blog/blog-3";
import BlogFour from "./components/Blog/blog-4";
import BlogFive from "./components/Blog/blog-5";
import BlogSix from "./components/Blog/blog-6";
import BlogTwo from "./components/Blog/blog-2";
import CHome from "./components/campaign/home";
import SiteMap from "./components/siteMap/siteMap";
import Services from "./components/services/services";

function App() {
  return (
    <Router>
      <div className="app">
        <Routes>
          <Route path="/sitemap" exact element={<SiteMap />} />
          <Route
            path="/building-demolition-bangalore"
            exact
            element={<CHome />}
          />
          <Route
            path="/blogs/5-tips-before-demolition-bangalore"
            exact
            element={<BlogSix />}
          />
          <Route
            path="/blogs/top-10-reasons-demolish-home-bangalore"
            exact
            element={<BlogFive />}
          />
          <Route
            path="/blogs/experts-demolition-services-bangalore"
            exact
            element={<BlogFour />}
          />
          <Route
            path="/blogs/house-demolition-cost-bangalore"
            exact
            element={<BlogThree />}
          />
          <Route
            path="/blogs/good-demolition-company"
            exact
            element={<BlogTwo />}
          />
          <Route
            path="/blogs/cost-of-demolition-in-bangalore"
            exact
            element={<BlogOne />}
          />
          <Route
            path="/services/old-building-demolition-bangalore"
            exact
            element={<BuildingDemolition />}
          />
          <Route
            path="/services/interior-demolition-bangalore"
            exact
            element={<InteriorDemolition />}
          />
          <Route
            path="/services/concrete-rcc-demolition-bangalore"
            exact
            element={<ConcreteDemolition />}
          />
          <Route
            path="/services/earth-excavation-bangalore"
            exact
            element={<Excation />}
          />
          <Route
            path="/services/overhead-tank-demolition-bangalore"
            exact
            element={<TankDemolition />}
          />
          <Route
            path="/services/construction-waste-cleanup-bangalore"
            exact
            element={<DebrisCleanup />}
          />
          <Route
            path="/services/boilers-chimneys-demolition-bangalore"
            exact
            element={<ChimneyDemolition />}
          />
          <Route
            path="/services/construction-material-supply-bangalore"
            exact
            element={<MaterialSUpply />}
          />
          <Route
            path="/services/tree-removal-service-bangalore"
            exact
            element={<TreeRemoval />}
          />
          <Route path="/projects" exact element={<Project />} />
          <Route path="/blogs" exact element={<Blogs />} />
          <Route path="/about-us" exact element={<AboutUs />} />
          <Route path="/services" exact element={<Services />} />
          <Route path="/contact-us" exact element={<ContactUs />} />
          <Route path="/" exact element={<Home />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
