import React, { Suspense, useState } from "react";
import axios from "axios";
const MyLazyComponent = React.lazy(() =>
  import("../Contact Us/MyLazyComponent")
);

const Contact = () => {
  const [mailerState, setMailerState] = useState({
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();

    console.log("You're good!");
    alert("Message Sent Successfully!!");

    await axios("https://nodemailer-web.onrender.com/sendToUser", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      data: mailerState,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    await axios("https://nodemailer-web.onrender.com/sendToAsif", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      data: mailerState,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log("Message Sent");
        } else if (res.status === 402) {
          console.log("Message failed to send");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await axios("https://nodemailer-web.onrender.com/sendToAbuzar", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      data: mailerState,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log("Message Sent");
        } else if (res.status === 402) {
          console.log("Message failed to send");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    await axios("https://nodemailer-web.onrender.com/sendToMuti", {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      data: mailerState,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          console.log("Message Sent");
        } else if (res.status === 402) {
          console.log("Message failed to send");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleStateChange(e) {
    setMailerState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  // function onChange(value) {
  //   if (value) setVerfied(true);
  // }

  return (
    <section className="contact" id="contact">
      <h1 className="heading"> contact us </h1>
      <div className="row">
        <iframe
          className="map"
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14676.076183382833!2d77.60700385227963!3d13.023555620349676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae17fb66ca2d95%3A0xea8c3e0488bf6f40!2sASA%20Building%20Demolition%20Contractor%20Bangalore!5e0!3m2!1sen!2sin!4v1675286800879!5m2!1sen!2sin"
          allowFullScreen=""
          loading="lazy"
        ></iframe>
        <form onSubmit={submitEmail} action="">
          <input
            required
            type="text"
            placeholder="Name"
            name="fullName"
            className="box"
            value={mailerState.fullName}
            onChange={handleStateChange}
          />

          <input
            required
            type="email"
            placeholder="Email"
            name="email"
            className="box"
            value={mailerState.email}
            onChange={handleStateChange}
          />

          <input
            required
            type="number"
            placeholder="Phone"
            name="phone"
            className="box"
            value={mailerState.phone}
            onChange={handleStateChange}
          />

          <textarea
            name="message"
            placeholder="Message"
            className="box"
            id=""
            cols="30"
            rows="10"
            value={mailerState.message}
            onChange={handleStateChange}
          />

          <div
            style={{ transformOrigin: "0 0", width: "100%" }}
            className="captcha"
          >
            <Suspense fallback={<p>loading...</p>}>
              <MyLazyComponent />
            </Suspense>
          </div>

          <input type="submit" value="send message" className="btn" />
        </form>
      </div>
    </section>
  );
};

export default Contact;
