import React from "react";
import Contact from "../../contactComponent/Contact";
import Footer from "../../Footer/footer";
import Navbar from "../../Navbar/navbar";
import Heading from "../../HeadingContainer/heading";
import Service from "../service";
import { titleChange } from "../../titleChange";
import { Helmet } from "react-helmet";

const ChimneyDemolition = () => {
  titleChange(
    "Boilers and Chimneys Demolition Services in Bangalore | ASA Group"
  );
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Demolisher provides professional boilers and chimneys demolition services in Bangalore. Our team has the expertise to safely and efficiently demolish boilers and chimneys. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Boilers and Chimneys Demolition Services in Bangalore, Construction Waste Cleanup Services in Bangalore, overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Boilers and Chimneys Demolition in Bangalore KA"
        name2="Simplifying Complex and Dangerous Demolition with the Best Standards"
        child1="Serivces"
        child1To="/services"
        child2="Boilers and Chimneys Demolition Bangalore"
        child2To="/services/boilers-chimneys-demolition-bangalore"
      />
      <Service
        para={
          <div>
            <p>
              Boilers and chimneys demolition is the process of safely removing
              an existing boiler and chimney structure. Boilers and chimneys are
              commonly found in industrial, commercial, and residential
              buildings, and are used to generate heat or dispose of flue gases.
              Over time, boilers and chimneys may become outdated or damaged,
              making demolition necessary.
            </p>
            <p>
              The process of boiler and chimney demolition typically involves
              the following steps:
            </p>
            <ul>
              <li>
                Planning and Preparation: Before demolition begins, a plan must
                be developed to ensure the safety of workers and the surrounding
                area. This may involve obtaining necessary permits and
                conducting a site survey to identify any potential hazards.
              </li>
              <li>
                Disconnection: The boiler and chimney must be disconnected from
                any gas, water, or electrical supply before demolition can
                begin. This helps to prevent any spills or contamination of the
                surrounding area.
              </li>
              <li>
                Structural Assessment: The structure of the boiler and chimney
                is evaluated to determine the most effective and efficient
                method of demolition. This may involve cutting, breaking, or
                dismantling the structure piece by piece.
              </li>
              <li>
                Demolition: The actual demolition process is carried out using
                heavy machinery, such as cranes, excavators, or demolition
                hammers. The demolition process must be carried out carefully to
                avoid damage to the surrounding area or structure.
              </li>
              <li>
                Clean-Up: Once the demolition process is complete, the site must
                be cleaned up and any debris removed. This helps to ensure the
                safety of the surrounding area and prevent any further damage or
                contamination.
              </li>
            </ul>
            <p>
              It is important to note that boiler and chimney demolition is a
              complex and dangerous process that should only be performed by
              experienced and professional contractors. Proper safety measures
              and equipment must be used to ensure the safety of workers and the
              surrounding area.
            </p>
          </div>
        }
        img={require("../../../images//Images for service pages/boilers-chimneys-demolition-bangalore.jpg")}
      />

      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default ChimneyDemolition;
