import React from "react";
import Contact from "../../contactComponent/Contact";
import Footer from "../../Footer/footer";
import Navbar from "../../Navbar/navbar";
import Heading from "../../HeadingContainer/heading";
import Service from "../service";
import { titleChange } from "../../titleChange";
import { Helmet } from "react-helmet";

const InteriorDemolition = () => {
  titleChange("Interior Demolition Services Near Me in Bangalore - ASA Group");
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Group provides damage proof reliable and cost-effective interior demolition services in Bangalore. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Interior Demolition Service Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Interior Demolition in Bangalore"
        name2="Deconstruct and Renovate to Create New Facilities"
        child1="Serivces"
        child1To="/services"
        child2="Interior Demolition Bangalore"
        child2To="/services/interior-demolition-bangalore"
      />
      <Service
        para={
          <div>
            <p>
              Interior demolition is the process of removing the internal
              structures of a building. It involves the removal of walls,
              flooring, ceilings, electrical and plumbing systems, and any other
              fixtures that are not a permanent part of the building's
              structure. In order to perform interior demolition, it's essential
              to have a clear understanding of the costs involved.
            </p>
            <p>
              Here are some key factors that can affect the cost of interior
              demolition in Bangalore:
            </p>
            <ul>
              <li>
                Size of the space - The larger the space that needs to be
                demolished, the higher the cost will be.
              </li>
              <li>
                Complexity of the job - If the building has multiple levels,
                challenging access points, or complicated electrical and
                plumbing systems, the cost of the demolition will be higher.
              </li>
              <li>
                Hazardous materials - The presence of hazardous materials such
                as asbestos, lead paint, or mold can significantly increase the
                cost of demolition.
              </li>
              <li>
                Salvageable materials - If you plan on salvaging any materials
                from the demolition, such as appliances, fixtures, or flooring,
                the cost will be lower.
              </li>
              <li>
                Method of demolition - The method of demolition used can also
                impact the cost, with more sophisticated methods like implosion
                or wrecking balls being more expensive.
              </li>
              <li>
                Location - The cost of interior demolition can also vary
                depending on the location of the building. In urban areas, the
                cost is typically higher due to the need for additional permits,
                as well as the higher cost of labor and materials.
              </li>
            </ul>
            <p>
              By considering these factors, you can be confident that you're
              choosing the right contractor for your concrete demolition
              project. Whether you're a developer, contractor, or homeowner,
              working with a professional concrete demolition contractor can
              ensure that your project is completed safely, efficiently, and
              within budget.
            </p>
          </div>
        }
        img={require("../../../images/Images for service pages/interior-demolition-bangalore.jpg")}
      />

      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default InteriorDemolition;
