import React from "react";
import "./blogComponent.css";

const BlogComponent = (props) => {
  return (
    <React.Fragment>
      <div className="blog-sections">
        <div className="blog-section-container">
          <div className="blog-section-img">
            <img src={props.img} alt="" />
          </div>
          <div className="blog-section-content">{props.para}</div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlogComponent;
