import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./navbar.css";
import logo from "../../images/ASA-GROUP-PNG.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronDown,
  faChevronRight,
  faPhoneSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const Navbar = () => {
  const [click, setClick] = useState(true);
  const [highlight, sethighlight] = useState("home");
  const [dropDown, setDropDown] = useState(false);

  const handleClick = () => {
    setClick(!click);
  };

  const handleIsActive = (active) => {
    sethighlight(active);
    console.log(highlight);
    console.log(active);
  };

  const handleDropDown = () => {
    setDropDown(!dropDown);
    console.log(dropDown);
  };

  const setDropDownTrue = () => {
    setDropDown(true);
  };

  const setDropDownFalse = () => {
    setDropDown(false);
  };

  return (
    <div className="header">
      <Link to="/" className="logo">
        <img loading="lazy" className="logo-image" src={logo} alt="" />
      </Link>

      <nav className={click ? "navbar" : "navbar active"}>
        <NavLink
          to="/"
          className="nav-Links"
          onClick={() => handleIsActive("home")}
          activeClassName={highlight === "home" ? "active" : ""}
        >
          home
        </NavLink>

        <NavLink
          to="/about-us"
          className="nav-Links"
          onClick={() => handleIsActive("aboutUs")}
          activeClassName={highlight === "aboutUs" ? "active" : ""}
        >
          About
        </NavLink>

        <div
          onMouseEnter={setDropDownTrue}
          onMouseLeave={setDropDownFalse}
          className="nav-service-Links"
          onClick={handleDropDown}
        >
          <ul>
            <li style={{ listStyleType: "none" }}>
              <span className="services-drop-down">
                Services
                {dropDown ? (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    style={{ marginLeft: 5, fontSize: 15 }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    style={{ marginLeft: 5, fontSize: 15 }}
                  />
                )}
              </span>
              <ul
                className={
                  dropDown ? "drop-down-nav active-drop" : "drop-down-nav"
                }
                onMouseLeave={setDropDownFalse}
              >
                <Link
                  to="/services/old-building-demolition-bangalore"
                  className="services-link"
                >
                  <li>Old Building Demolition</li>
                </Link>
                <Link
                  to="/services/interior-demolition-bangalore"
                  className="services-link"
                >
                  <li>Interior Demolition</li>
                </Link>
                <Link
                  to="/services/concrete-rcc-demolition-bangalore"
                  className="services-link"
                >
                  <li>Concrete Demolition</li>
                </Link>
                <Link
                  to="/services/earth-excavation-bangalore"
                  className="services-link"
                >
                  <li>Excavation</li>
                </Link>
                <Link
                  to="/services/overhead-tank-demolition-bangalore"
                  className="services-link"
                >
                  <li>Overhead Tank Demolition</li>
                </Link>
                <Link
                  to="/services/construction-waste-cleanup-bangalore"
                  className="services-link"
                >
                  <li>Debris Cleanup</li>
                </Link>
                <Link
                  to="/services/boilers-chimneys-demolition-bangalore"
                  className="services-link"
                >
                  <li>Boilers and Chimneys Demolition</li>
                </Link>
                <Link
                  to="/services/construction-material-supply-bangalore"
                  className="services-link"
                >
                  <li>Material Supply</li>
                </Link>
                <Link
                  to="/services/tree-removal-service-bangalore"
                  className="services-link"
                >
                  <li>Tree Removal Services</li>
                </Link>
              </ul>
            </li>
          </ul>
        </div>

        <NavLink
          to="/projects"
          className="nav-Links"
          onClick={() => handleIsActive("project")}
          activeClassName={highlight === "project" ? "active" : ""}
        >
          Projects
        </NavLink>

        <NavLink
          to="/contact-us"
          className="nav-Links"
          onClick={() => handleIsActive("contact")}
          activeClassName={highlight === "contact" ? "active" : ""}
        >
          Contact
        </NavLink>

        <NavLink
          to="/blogs"
          className="nav-Links"
          onClick={() => handleIsActive("blogs")}
          activeClassName={highlight === "blogs" ? "active" : ""}
        >
          Blogs
        </NavLink>
      </nav>

      <a href="tel:953-898-9075">
        <div className="icons">
          <p className="nav-phone">
            <FontAwesomeIcon icon={faPhoneSquare} className="phone-icon" />
            812-398-9074
          </p>
        </div>
      </a>
      <FontAwesomeIcon
        onClick={handleClick}
        icon={faBars}
        className="menu-btn"
      />
    </div>
  );
};

export default Navbar;
