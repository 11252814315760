import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Contact from "../contactComponent/Contact";
import Footer from "../Footer/footer";
import Heading from "../HeadingContainer/heading";
import Navbar from "../Navbar/navbar";
import { titleChange } from "../titleChange";
import "./sitemap.css";

const SiteMap = () => {
  titleChange("Sitemap | ASA Group");
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="Explore the complete site map of ASA Demolisher's website. Navigate through our pages to learn more about our services and projects. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="Demolition Projects Bangalore, Tree Removal Services in Bangalore, Construction Material Supply Services in Bangalore, Construction Waste Cleanup Services in Bangalore, overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>

      <Navbar />
      <Heading name="SiteMap" child1="Sitemap" child1To="/site-map" />
      <div className="sitmap-container">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/services">Services</Link>
            <ul className="subLinks-list">
              <li className="subLinks">
                <Link to="/services/old-building-demolition-bangalore">
                  Old Building Demolition
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/interior-demolition-bangalore">
                  Interior Demolition
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/concrete-rcc-demolition-bangalore">
                  Concrete RCC Demolition
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/earth-excavation-bangalore">
                  Earth Excavation
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/overhead-tank-demolition-bangalore">
                  Overhead Tank Demolition
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/construction-waste-cleanup-bangalore">
                  Construction Waste Cleanup
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/boilers-chimneys-demolition-bangalore">
                  Boilers and Chimneys Demolition
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/construction-material-supply-bangalore">
                  Construction Material Supply
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/services/tree-removal-service-bangalore">
                  Tree Removal Service
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link>
            <ul className="subLinks-list">
              <li className="subLinks">
                <Link to="/blogs/cost-of-demolition-in-bangalore">
                  Cost of Demolition in Bangalore
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/blogs/good-demolition-company">
                  Good Demolition Company
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/blogs/house-demolition-cost-bangalore">
                  House Demolition Cost in Bangalore
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/blogs/experts-demolition-services-bangalore">
                  Experts Demolition Services
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/blogs/top-10-reasons-demolish-home-bangalore">
                  Top 10 Reasons to Demolish your Home
                </Link>
              </li>
              <li className="subLinks">
                <Link to="/blogs/5-tips-before-demolition-bangalore">
                  5 Tips before taking Service
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default SiteMap;
