import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const HReviews = () => {
  return (
    <section className="reviews">
      <h1 className="heading"> client reviews </h1>
      <Swiper
        loop={true}
        grabCursor={true}
        spaceBetween={20}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        modules={[Autoplay]}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
        }}
      >
        <div className="swiper reviews-slider">
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div className="swiper-slide slide">
                <p>
                  I found the ASA demolisher online. I was little cautious since
                  our house was old and tricky in some places, and surrounded by
                  other houses....
                  <br />
                  <br />
                  <a
                    className="read-more-button"
                    href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                  >
                    <img
                      loading="lazy"
                      className="google-image"
                      src={require("../../images/googleIcon-1.webp")}
                      alt=""
                    />
                  </a>
                </p>
                <div className="user">
                  <img
                    loading="lazy"
                    src={require("../../images/Reviews-1/Santosh.png")}
                    alt=""
                  />
                  <div className="info">
                    <h3>Santosh Kumar</h3>
                    <div className="stars">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide">
                <p>
                  They have done very good work with professionalism . Within 4
                  days they cleared our site . They have good knowledge in
                  demolishing houses...
                  <br />
                  <br />
                  <a
                    className="read-more-button"
                    href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                  >
                    <img
                      loading="lazy"
                      className="google-image"
                      src={require("../../images/googleIcon-1.webp")}
                      alt=""
                    />
                  </a>
                </p>
                <div className="user">
                  <img
                    loading="lazy"
                    src={require("../../images/Reviews-1/Saqlain.png")}
                    alt=""
                  />
                  <div className="info">
                    <h3>K Samiulla</h3>
                    <div className="stars">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide">
                <p>
                  One of the oldest and professional demolishers in Bangalore
                  with professional supervisors, Labourers and own machines.
                  Excellent job by...
                  <br />
                  <br />
                  <a
                    className="read-more-button"
                    href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                  >
                    <img
                      loading="lazy"
                      className="google-image"
                      src={require("../../images/googleIcon-1.webp")}
                      alt=""
                    />
                  </a>
                </p>
                <div className="user">
                  <img
                    loading="lazy"
                    src={require("../../images/Reviews-1/Sadaqath.png")}
                    alt=""
                  />
                  <div className="info">
                    <h3>SiD Sadaqath</h3>
                    <div className="stars">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide">
                <p>
                  Asif did a great job with the demolition work at our property.
                  The work was thorough and the place was left clean after the
                  job was done. Our...
                  <br />
                  <br />
                  <a
                    className="read-more-button"
                    href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                  >
                    <img
                      loading="lazy"
                      className="google-image"
                      src={require("../../images/googleIcon-1.webp")}
                      alt=""
                    />
                  </a>
                </p>
                <div className="user">
                  <img
                    loading="lazy"
                    src={require("../../images/Reviews-1/Sandeep.png")}
                    alt=""
                  />
                  <div className="info">
                    <h3>Sandeep Bantia</h3>
                    <div className="stars">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide">
                <p>
                  Thank you ASA group. The Demolition work was conducted very
                  smoothly and efficiently. It was great to have it completed
                  before the....
                  <br />
                  <br />
                  <a
                    className="read-more-button"
                    href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                  >
                    <img
                      loading="lazy"
                      className="google-image"
                      src={require("../../images/googleIcon-1.webp")}
                      alt=""
                    />
                  </a>
                </p>
                <div className="user">
                  <img
                    loading="lazy"
                    src={require("../../images/Reviews-1/Prem.png")}
                    alt=""
                  />
                  <div className="info">
                    <h3>prem raj</h3>
                    <div className="stars">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="swiper-slide slide">
                <p>
                  Excellent work done by the owner and his staff everyone were
                  very polite and hardworking. Got the job done in given time.
                  Honesty, trustworthy...
                  <br />
                  <br />
                  <a
                    className="read-more-button"
                    href="https://goo.gl/maps/fL5bc7b41P2zee9T6"
                  >
                    <img
                      loading="lazy"
                      className="google-image"
                      src={require("../../images/googleIcon-1.webp")}
                      alt=""
                    />
                  </a>
                </p>
                <div className="user">
                  <img
                    loading="lazy"
                    src={require("../../images/Reviews-1/Irfan.png")}
                    alt=""
                  />
                  <div className="info">
                    <h3>Irfan Qureshi</h3>
                    <div className="stars">
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                      <FontAwesomeIcon
                        icon={faStar}
                        style={{ color: "#f5bf23", fontSize: 15 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </div>
        </div>
      </Swiper>
    </section>
  );
};

export default HReviews;
