import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const ProjectContent = (props) => {
  const rotate = props.dropDown === props.name ? "rotate(0)" : "rotate(180deg)";
  return (
    <React.Fragment>
      <div className="project-content-list">
        <div
          className="project-content-main-content"
          onClick={() => props.setDropDown(props.name)}
        >
          <li className="project-content-items">{props.name}</li>
          <FontAwesomeIcon
            className="dropDropIcon"
            icon={faChevronUp}
            style={{
              float: "right",
              transform: rotate,
              transition: "all 0.4s ease",
            }}
          />
        </div>

        <div
          className={
            props.dropDown === props.name
              ? `project-main-content active-${props.id}`
              : "project-main-content"
          }
        >
          <div className="content-video">{props.youtube}</div>
          <div className="content-img-container">
            <div className="content-images">
              {props.image1 ? (
                <div className="project-content-image-div">
                  <img src={props.image1} alt="" />
                </div>
              ) : null}
              {props.image2 ? (
                <div className="project-content-image-div">
                  <img src={props.image2} alt="" />
                </div>
              ) : null}
              {props.image3 ? (
                <div className="project-content-image-div">
                  <img src={props.image3} alt="" />
                </div>
              ) : null}
              {props.image4 ? (
                <div className="project-content-image-div">
                  <img src={props.image4} alt="" />
                </div>
              ) : null}
              {props.image5 ? (
                <div className="project-content-image-div">
                  <img src={props.image5} alt="" />
                </div>
              ) : null}
              {props.image6 ? (
                <div className="project-content-image-div">
                  <img src={props.image6} alt="" />
                </div>
              ) : null}
              {props.image7 ? (
                <div className="project-content-image-div">
                  <img src={props.image7} alt="" />
                </div>
              ) : null}
              {props.image8 ? (
                <div className="project-content-image-div">
                  <img src={props.image8} alt="" />
                </div>
              ) : null}
              {props.image9 ? (
                <div className="project-content-image-div">
                  <img src={props.image9} alt="" />
                </div>
              ) : null}
              {props.image10 ? (
                <div className="project-content-image-div">
                  <img src={props.image10} alt="" />
                </div>
              ) : null}
              {props.image11 ? (
                <div className="project-content-image-div">
                  <img src={props.image11} alt="" />
                </div>
              ) : null}
              {props.image12 ? (
                <div className="project-content-image-div">
                  <img src={props.image12} alt="" />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProjectContent;
