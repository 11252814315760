import React from "react";
import Contact from "../../contactComponent/Contact";
import Footer from "../../Footer/footer";
import Navbar from "../../Navbar/navbar";
import Heading from "../../HeadingContainer/heading";
import Service from "../service";
import { titleChange } from "../../titleChange";
import { Helmet } from "react-helmet";

const BuildingDemolition = () => {
  titleChange(
    "Old Building or House Demolition Services Near Me in Bangalore - ASA Group"
  );
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Building Demolition Contractor provide safe and efficient old building demolition services in Bangalore. Contact us today at ☎️ 812-398-9074 for a free estimate."
        />
        <meta
          name="keywords"
          content="Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Old Building or House Demolition in Bangalore"
        name2="Destroying the Structure with Controlled Methods"
        child1="Serivces"
        child1To="/services"
        child2="Building Demolition Bangalore"
        child2To="/services/old-building-demolition-bangalore"
      />
      <Service
        para={
          <div>
            <p>
              When it comes to demolition of houses or buildings, it's essential
              to have a trusted and experienced partner to ensure the process is
              safe and efficient. Our demolition service team at ASA Demolisher
              is dedicated to providing professional and cost-effective
              demolition solutions to meet your needs. With decades of
              experience in the demolition and construction industry, we have
              the knowledge and skills to carry out even the most complex
              projects.
            </p>
            <p>
              Health, safety, and well-being are our top priorities, and we
              adhere to strict safety standards and guidelines. Our team will
              advise and assist in the selection of the appropriate methodology,
              type, and sequence of demolition, de-construction, and engineering
              solutions to ensure a seamless and safe removal process. We
              understand that every demolition project is unique, and our team
              will work with you to tailor a solution that fits your specific
              requirements.
            </p>
            <p>
              We have a close-knit team of experienced and highly skilled staff
              who are passionate about their work. Our staff members have the
              expertise and experience to handle all aspects of the demolition
              process, from site preparation to debris removal. We believe in
              providing our clients with the best experience possible and strive
              to make the process as stress-free as possible.
            </p>
            <p>
              Honesty and realism are core values in our approach, and we
              believe in being straightforward with our clients. Our goal is to
              focus on getting the job done right and delivering what our
              clients want and more. We understand the importance of meeting
              deadlines and budget constraints, and our team will work with you
              to ensure your project is completed on time and within budget.
            </p>
            <p>
              At ASA Demolisher, we are committed to providing safe, efficient,
              and professional building demolition services in Bangalore. If
              you're in need of a demolition partner, contact us today to
              schedule a consultation. Let us take care of the demolition
              process so you can focus on your future plans.
            </p>
          </div>
        }
        img={require("../../../images/Images for service pages/old-building-demolition-bangalore.jpg")}
      />

      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default BuildingDemolition;
