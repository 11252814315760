import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import React from "react";
import "./heading.css";

const Heading = (props) => {
  return (
    <React.Fragment>
      <div className="main-heading-container">
        <div
          className="main-head-container"
          style={{ backgroundImage: require("../../images/digger.jpg") }}
        >
          <h1 className="main-heading-text">{props.name}</h1>
          <h2>{props.name2}</h2>
        </div>
      </div>
      <div className={props.style1 ? "bread-crumbs active" : "bread-crumbs"}>
        <div className="subs">
          <Link to="/">
            <p className="p">Home</p>
          </Link>
          <FontAwesomeIcon icon={faChevronRight} />
          <Link to={props.child1To}>
            <p className={props.child2 ? "p" : "activeCr"}>{props.child1}</p>
          </Link>
        </div>
        <div className="subs1">
          {props.child2 ? <FontAwesomeIcon icon={faChevronRight} /> : null}
          {props.child2 ? (
            <Link>
              <p className={props.child2 ? "activeCr" : "p"}>{props.child2}</p>
            </Link>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Heading;
