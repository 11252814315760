import React from "react";
import Contact from "../../contactComponent/Contact";
import Footer from "../../Footer/footer";
import Navbar from "../../Navbar/navbar";
import Heading from "../../HeadingContainer/heading";
import Service from "../service";
import { titleChange } from "../../titleChange";
import { Helmet } from "react-helmet";

const TankDemolition = () => {
  titleChange("Overhead Tank Demolition Services in Bangalore | ASA Group");
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="description"
          content="ASA Demolisher provides professional overhead tank demolition services in Bangalore. Trust us to safely and efficiently remove your overhead tank. Contact us today at ☎️ 812-398-9074"
        />
        <meta
          name="keywords"
          content="overhead tank demolition Bangalore, Earth Excavation Services Bangalore, Concrete RCC Demolition Services Bangalore, Demolition Contractor Near Me in Bangalore, Excavation Contractor Near Me in Bangalore, ASA Demolisher, Commercial Demolition Contractor Near Me in Bangalore, Residential Demolition Contractor Near Me in Bangalore, Industrial Demolition Contractor Near Me in Bangalore, Factory Demolition Contractor Near Me in Bangalore, Tree Removal and Demolition Contractor Near Me in Bangalore, Interior Demolition Service Bangalore"
        />
      </Helmet>
      <Navbar />
      <Heading
        name="Overhead Tank Demolition in Bangalore"
        name2="Implode the High Rise to Bite the Dust"
        child1="Serivces"
        child1To="/services"
        child2="Overhead Tank Demolition Bangalore"
        child2To="/services/overhead-tank-demolition-bangalore"
      />
      <Service
        para={
          <div>
            <p>
              Overhead tank demolition is the process of safely and efficiently
              removing an existing overhead tank. Overhead tanks are large,
              elevated structures that are used to store water or other liquids
              for industrial or residential use. Demolition of these tanks is
              necessary for various reasons, including structural damage,
              outdated design, or the need for expansion or renovation.
            </p>
            <p>
              To effectively implode the overhead tank it requires the right
              amount of power and force with the right demolition process to
              attain the best results. The use of "implode" highlights the
              controlled nature of the demolition and the precision required to
              bring a high-rise building down in a safe and controlled manner.
              This emphasizes the ultimate goal of the demolition, which is to
              clear the way for new development.
            </p>
            <p>
              The process of overhead tank demolition typically involves the
              following steps:
            </p>
            <ul>
              <li>
                Planning and Preparation: Before the demolition process begins,
                a plan must be developed to ensure the safety of workers and the
                surrounding area. This may involve obtaining necessary permits
                and conducting a site survey to identify any potential hazards.
              </li>
              <li>
                Disconnection and Drainage: The tank must be disconnected from
                its water supply and drained of any liquids before demolition
                can begin. This helps to prevent any spills or contamination of
                the surrounding area.
              </li>
              <li>
                Structural Assessment: The structure of the tank is evaluated to
                determine the most effective and efficient method of demolition.
                This may involve cutting, breaking, or dismantling the tank
                piece by piece.
              </li>
              <li>
                Demolition: The actual demolition process is carried out using
                heavy machinery, such as cranes, excavators, or demolition
                hammers. The demolition process must be carried out carefully to
                avoid damage to the surrounding area or structure.
              </li>
              <li>
                Clean-Up: Once the demolition process is complete, the site must
                be cleaned up and any debris removed. This helps to ensure the
                safety of the surrounding area and prevent any further damage or
                contamination.
              </li>
            </ul>
            <p>
              It is important to note that overhead tank demolition is a complex
              and dangerous process that should only be performed by experienced
              and professional contractors. Proper safety measures and equipment
              must be used to ensure the safety of workers and the surrounding
              area.
            </p>
          </div>
        }
        img={require("../../../images/Images for service pages/overhead-tank-demolition-bangalore.jpeg")}
      />

      <Contact />
      <Footer />
    </React.Fragment>
  );
};

export default TankDemolition;
